<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">遗产所在地社会环境年度监测记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item prop="ND" label="年度" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.ND"
                        type="year"
                        placeholder="选择年份"
                        value-format="yyyy"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="RKMD"
                    label="人口密度"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.RKMD"
                        @input="toDecimal($event, 'RKMD')"
                        :disabled="!isEdit"
                        ><template slot="append"
                            >人/每平方公里</template
                        ></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="RJGDP"
                    label="人均GDP"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.RJGDP"
                        @input="toDecimal($event, 'RJGDP')"
                        @blur="blurMoney('RJGDP')"
                        @focus="focusMoney('RJGDP')"
                        :disabled="!isEdit"
                        ><template slot="append">美元</template></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="GJBHDZWZL"
                    label="国家保护动植物种类"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.GJBHDZWZL"
                        @input="toNumber($event, 'GJBHDZWZL')"
                        :disabled="!isEdit"
                        ><template slot="append">项</template></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="ZBFGL"
                    label="植被覆盖率"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.ZBFGL"
                        @input="limitPercent($event, 'ZBFGL')"
                        :disabled="!isEdit"
                        ><template slot="append">%</template></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="YCSZDYZWRGYQYSL"
                    label="遗产所在地严重污染工业企业数量"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.YCSZDYZWRGYQYSL"
                        @input="toNumber($event, 'YCSZDYZWRGYQYSL')"
                        :disabled="!isEdit"
                        ><template slot="append">个</template></el-input
                    >
                </el-form-item>

                <el-form-item prop="ZRK" label="总人口" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.ZRK"
                        @input="toNumber($event, 'ZRK')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="GDMJ"
                    label="耕地面积"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.GDMJ"
                        @input="toNumber($event, 'GDMJ')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">亩</template></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="NZWCL"
                    label="农作物产量"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.NZWCL"
                        @input="toNumber($event, 'NZWCL')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">公斤</template></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="CYMJ"
                    label="茶园面积"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.CYMJ"
                        @input="toNumber($event, 'CYMJ')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">亩</template></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="CYZCL"
                    label="茶叶总产量"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.CYZCL"
                        @input="toNumber($event, 'CYZCL')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">公斤</template></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="JJZCR"
                    label="经济总收入"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JJZCR"
                        @input="toNumber($event, 'JJZCR')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">元</template></el-input
                    >
                </el-form-item>
                <el-form-item
                    prop="RJZSR"
                    label="人均总收入"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.RJZSR"
                        @input="toNumber($event, 'RJZSR')"
                        :disabled="!isEdit"
                    >
                        <template slot="append">元</template></el-input
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "ycszdshhjndjcjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1004",
                dataDic: {
                    glycbtid: this.heritageId,
                    ND: "",
                    RKMD: "",
                    RJGDP: "",
                    GJBHDZWZL: "",
                    ZBFGL: "",
                    YCSZDYZWRGYQYSL: "",
                    ZRK: "", //总人口
                    GDMJ: "", //耕地面积（亩）
                    NZWCL: "", //农作物产量（公斤）
                    CYMJ: "", //茶园面积（亩）
                    CYZCL: "", //茶叶总产量（公斤）
                    JJZCR: "", //经济总收入（元）
                    RJZSR: "", //人均总收入（元）
                },
            },
            rules: {
                ND: [
                    {
                        required: true,
                        message: "请选择年度",
                        trigger: "change",
                    },
                ],
            },
            enumData: [],
            toMoneyProps: ["RJGDP"],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
